if($(window).width() > 1200) {
	gsap.utils.toArray('.image-text-fixed-trigger').forEach((element) => {
		let imgContainer = $(element).find('.image-container')
		let img = $(element).find('.img-container .img-wrapper')
		let content = $(element).find('.content-container')
		let title = $(content).find('h2')
		let text = $(content).find('p')
	
		gsap.to(
			element,
			{
				scrollTrigger: {
					markers: false,
					trigger: element,
					pin: element,
					start: "top top",
					end: "bottom bottom",
					scrub: 1,
					pinSpacing: false
				}
			}
		)
		const tline = gsap.timeline({
			scrollTrigger: {
				markers: false,
				trigger: content,
				pin: false,
				start: "top top",
				end: "bottom -150%",
				scrub: 1,
				pinSpacing: false
			}
		})
		tline.to(content,{yPercent:-100, duration:1})
		tline.to(img[1],{opacity:1, duration:0.5},"<20%")
	
		gsap.utils.toArray(title).forEach((element) => {
			gsap.to(
				element,
				{
					y:"-20vh",
					scrollTrigger: {
						markers: false,
						trigger: element,
						pin: false,
						start: "top -20%",
						end: "bottom -150%",
						scrub: 1,
						pinSpacing: false
					}
				}
			)
		})
		
	
	})
} else {
	gsap.utils.toArray('.image-text-fixed-trigger').forEach((element) => {
		let imgContainer = $(element).find('.img-container')
        let img = $(element).find('.img-wrapper')
		let content = $(element).find('.content-container')
        
		gsap.to(
            element,
            {
				scrollTrigger: {
					markers: false,
					trigger: content,
					pin: imgContainer,
					start: "top 0",
					end: "bottom bottom",
					scrub: 0,
					pinSpacing: true
				}
			}
		)
		const tline = gsap.timeline({
			scrollTrigger: {
				markers: false,
				trigger: content,
				pin: false,
				start: "top -50%",
				end: "bottom 100%",
				scrub: 1,
				pinSpacing: false
			}
		})
		tline.to(img[1],{opacity:1, duration:0.5})
	})
}

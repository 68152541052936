let portfolioItem = $('.portfolio-row')
let portfolioHover = $('.portfolio-row .left-text')
let header = $('header')
let image = $('.portfolio-section .img-wrapper')
let portfolio = $('portfolio-section')

portfolioHover.on('mouseover', function(e){
	$(this).removeClass('hidden-row')
	let toHide = $('.portfolio-row')
	let thisWrapper = $(this).closest('.portfolio-row')
	toHide.not(thisWrapper).addClass('hidden-row')
	thisWrapper.addClass('hovered')
	header.addClass('white-header-addon')
	document.addEventListener("scroll", (event) => {
		portfolioItem.removeClass('hidden-row')
		header.removeClass('white-header-addon');
		portfolioItem.removeClass('hovered')
	});
})
portfolioHover.on('mouseleave', function(e){
	portfolioItem.removeClass('hidden-row')
	header.removeClass('white-header-addon')
	portfolioItem.removeClass('hovered')
})


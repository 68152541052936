if($(window).width() > 992) {
	gsap.utils.toArray('.people-parallax').forEach((element) => {
		let card = $(element).find('.card')
		let cardRight = $(element).find('.card-right')
		let cardLeft = $(element).find('.card-left')
		
		gsap.to(
			cardRight, {
				duration: 1,
				y: "0%",
				scrollTrigger: {
					markers: false,
					trigger: element,
					start: "top top",
					end: "100%",
					scrub: 1,
					pinSpacing: false
				}
			}
		)
		gsap.to(
			cardLeft, {
				duration: 1,
				y: '-20%',
				scrollTrigger: {
					markers: false,
					trigger: element,
					start: "top top",
					end: "100%",
					scrub: 1,
					pinSpacing: false
				}
			}
		)
	})
}
gsap.utils.toArray('.people-parallax').forEach((element) => {
	let card = $(element).find('.card')
	gsap.utils.toArray(card).forEach((element) => {
		let img = $(element).find('img')
		gsap.to(
			img, {
				duration: 1,
				y: '15%',
				scrollTrigger: {
					markers: false,
					trigger: element,
					start: "top 70%",
					end: "90%",
					scrub: 0,
					pinSpacing: false
				}
			}
		)
	})
})
//Wedoo-----------------------------------------------------------------------------------
//-video-fixed
if($(window).width() > 1200) {
	gsap.utils.toArray('.video-scroll-trigger').forEach((element) => {
		let qty = $(element).find('.claim-content').length + 2;
		let title = $(element).find('.claim-content')
		let trg = $(element).find('.video-scroll-content')
		const tl = gsap.timeline({
			scrollTrigger: {
				markers: false,
				trigger: trg,
				pin: trg,
				start: "top top",
				end: "bottom bottom",
				scrub: 1,
				pinSpacing: false
			}
		})
		tl.to(title[0],{opacity:1, duration:1})
		tl.to(title[0],{opacity:1, duration:1})
		tl.to(title[0],{opacity:0, duration:1})
		tl.to(title[1],{opacity:1, duration:1})
		tl.to(title[1],{opacity:1, duration:1})
		tl.to(title[1],{opacity:0, duration:1})
		tl.to(title[2],{opacity:1, duration:1})
		tl.to(title[2],{opacity:1, duration:1})
		tl.to(title[2],{opacity:0, duration:1})
	})
} else {
	gsap.utils.toArray('.video-scroll-trigger').forEach((element) => {
		let title = $(element).find('.claim-content')
		let trg = $(element).find('.video-scroll-content')
		gsap.to(trg,{
			scrollTrigger: {
				markers: false,
				trigger: element,
				pin: false,
				start: "top top",
				end: "bottom bottom",
				scrub: 1,
				pinSpacing: false
			}
		})
		const tl = gsap.timeline({repeat:-1})
		tl.to(title[0],{opacity:1, duration:1})
		tl.to(title[0],{opacity:1, duration:1})
		tl.to(title[0],{opacity:0, duration:1})
		tl.to(title[1],{opacity:1, duration:1})
		tl.to(title[1],{opacity:1, duration:1})
		tl.to(title[1],{opacity:0, duration:1})
		tl.to(title[2],{opacity:1, duration:1})
		tl.to(title[2],{opacity:1, duration:1})
		tl.to(title[2],{opacity:0, duration:1})
	})
}
gsap.utils.toArray('.text-reveal').forEach((element) => {
	gsap.to(
		$(element).find('span'),{
		y:0,
		duration:1,
		scrollTrigger: {
			markers: false,
			trigger: element,
			start: "bottom bottom",
			end: "top 40%",
			pin: false,
			scrub: 1,
			pinSpacing: false
		}
	})
})
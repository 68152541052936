const main = $('#main.main-color-change')

gsap.utils.toArray('.color-change').forEach((element) => {
	let bg;
	if($(element).hasClass('change-dark')) {
		bg = "var(--body-bg-dark)"
	}
	if($(element).hasClass('change-light')) {
		bg = "var(--body-bg)"
	}

	gsap.to(
		main, {
			backgroundColor: bg,
			scrollTrigger: {
				markers: false,
				trigger: element,
				start: "top 50%",
				end: "bottom 10%",
				scrub: 0,
				pinSpacing: false
			}
		}
	)
})
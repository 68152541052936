$(document).ready(function(){
	let heroBg = $('#hero-bg-image');
	let heroBgMob = heroBg.data('img-background-mobile');
	let windowWidth = $(window).width();
	if ((windowWidth < 768) & heroBgMob != undefined) {
		heroBg.css('background-image','url("'+heroBgMob+'")')
	}
})

$(window).on('resize', function(){
	let heroBg = $('#hero-bg-image');
	let heroBgMob = heroBg.data('img-background-mobile');
	let heroBgDesk= heroBg.data('img-background-desktop');
	let windowWidth = $(window).width();
	if ((windowWidth < 768) & heroBgMob != undefined) {
		heroBg.css('background-image','url("'+heroBgMob+'")')
	} else {
		heroBg.css('background-image','url("'+heroBgDesk+'")')
	}
});


let heroTitle = $('.hero-title');
let hero = $('.hero');
let h;

if($(window).width() > 992) {
	h = 170
}else{
	h = 200
}
gsap.to(heroTitle,
	{
		y:h,
		scrollTrigger: {
			markers: false,
			trigger: hero,
			pin: false,
			start: "top top",
			end: "bottom -50%",
			scrub: 0,
			pinSpacing: false
		}
		
	}
)
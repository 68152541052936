//footer padding on load
$( document ).ready(function() {
	let footerHeight = $('#phoenix-footer').outerHeight();
	document.querySelector(':root').style.setProperty('--footer-height', footerHeight+'px')
});

//footer padding on resize
$(window).on('resize', function(){
	let footerHeight = $('#phoenix-footer').outerHeight();
	document.querySelector(':root').style.setProperty('--footer-height', footerHeight+'px')
});

//import css
import "./scss/main.scss";

//import components js
import "./components/button/button.js";
import "./components/card/card.js";
import "./components/color-change/color-change.js";
import "./components/contact-link/contact-link.js";
import "./components/content-row/content-row.js";
import "./components/cta-big/cta-big.js";
import "./components/fixed-component/fixed-component.js";
import "./components/footer/footer.js";
import "./components/header/header.js";
import "./components/hero/hero.js";
import "./components/image-text-fixed/image-text-fixed.js";
import "./components/image-text-pinned/image-text-pinned.js";
import "./components/img-h-scroll/img-h-scroll.js";
import "./components/logos-list/logos-list.js";
import "./components/magic-cursor/magic-cursor.js";
import "./components/map/map.js";
import "./components/moving-title/moving-title.js";
import "./components/nav/nav.js";
import "./components/news/news.js";
import "./components/people-parallax/people-parallax.js";
import "./components/portfolio-section/portfolio-section.js";
import "./components/preloader/preloader.js";
import "./components/reveal-section/reveal-section.js";
import "./components/text-centered/text-centered.js";
import "./components/text-parallax/text-parallax.js";
import "./components/text-reveal/text-reveal.js";
import "./components/title-reveal/title-reveal.js";
import "./components/title-text/title-text.js";
import "./components/video-scroll/video-scroll.js";

import "./js/browser-detect";
import "./js/lazyload";



/**
 * Checks if the device is a mobile device
 * @return {boolean} true if the device is a mobile device, false otherwise
 */

/*
	Activation class:
		- cursor-magic-modifier
	params:
		- data-cursor-img
		- data-cursor-class
		- data-cursor-bg-color
		- data-cursor-border-color
		- data-cursor-color
*/

function isMobile() {
	if (navigator.userAgent.match(/Android/i)
	  || navigator.userAgent.match(/webOS/i)
	  || navigator.userAgent.match(/iPhone/i)
	  || navigator.userAgent.match(/iPad/i)
	  || navigator.userAgent.match(/iPod/i)
	  || navigator.userAgent.match(/BlackBerry/i)
	  || navigator.userAgent.match(/Windows Phone/i)) {
	  return true;
	}
	return false;
}

let cursorModifier = $('.cursor-magic-modifier');
let cursor = $("#magic-cursor");
let ball = $("#ball");
let ballLoader = $("#ball-loader");
let cursorImg;
let cursorClass;
let cursorBgColor;
let cursorColor;
let cursorBorderColor;

if (!isMobile()) {
	$(cursorModifier).mouseenter(function(e) {
		
		var $this = $(this);
		
		cursorImg = $this.data('cursor-img');
        cursorClass = $this.data('cursor-class');
		cursorBgColor = $this.data('cursor-bg-color');
		cursorBorderColor = $this.data('cursor-border-color');
		cursorColor = $this.data('cursor-color');

		gsap.to(
			ball,{
				duration: 0.3,
				borderWidth: '2px',
				scale: 1.5,
				backgroundColor: cursorBgColor,
				borderColor: cursorBorderColor,
				color: cursorColor
			});
		gsap.to(
			ballLoader,{
				duration: 0.2,
				borderWidth: '2px',
				top: 2,
				left: 2
			});
		if(cursorImg) {
			ball.append('<img class="cursor-img" src="' + cursorImg + '" />');
		}
		ball.addClass(cursorClass);
	});


	$(cursorModifier).mouseleave(function(e) {
		var $this = $(this);
        // Get the cursor class
        cursorClass = $this.data('cursor-class');
		gsap.to(
			ball, {
				duration: 0.5,
				borderWidth: '4px',
				scale:0.5,
				borderColor:'#999999',
				backgroundColor:'transparent',
				color: 'inherit',
			}
		);
		gsap.to(
			ballLoader, {
				duration: 0.5,
				borderWidth: '4px',
				top: 0, left: 0
			}
		);
		$(ball).find('img').remove()
		ball.removeClass(cursorClass);
	});
}


gsap.utils.toArray('.image-text-pinned').forEach((element) => {
	let img = $(element).find('.img-container')
	gsap.to(
		img,
		{
			backgroundPosition:"0 40vh",
			duration:0.5,
			ease: 'none',
			scrollTrigger: {
				trigger: element,
				markers: false,
				start: "top top",
				end: "100%",
				pin: false,
				scrub: 0,
				pinSpacing: false,
				ease: 'none',
			}
		}
	)
})
gsap.utils.toArray('.text-parallax').forEach((element) => {
	const title = $(element).find('.title-reveal')
	gsap.to(
		title, {
			duration: 1,
			y: "-10vh",
			scrollTrigger: {
				markers: false,
				trigger: element,
				start: "top 10%",
				end: "100%",
				scrub: 1,
				pinSpacing: false
			}
		}
	)
})
var controller = new ScrollMagic.Controller();


if ($("body").hasClass("smooth-scroll")) {
	var elem = document.querySelector("#content-scroll");
	var scrollbar = Scrollbar.init(elem,
	{renderByPixels: true,damping:0.1});
}
$(".img-h-scroll").each( function () {
	var $this = $(this);
	var mask = $this.find('.img-wrapper');
	var height = $( window ).height();
	var animationMask
	if(mask.hasClass('zoom-in')){
		animationMask = gsap.to( mask, 1, {width: '100%',borderRadius: 0 , ease:Linear.easeNone, });
	}
	if(mask.hasClass('zoom-out')){
		animationMask = gsap.to( mask, 1, {width: '80%', borderRadius: 32 , ease:Linear.easeNone});
	}
	// Create scrollmagic scene
	var animationMaskScene = new ScrollMagic.Scene({
		triggerElement: this, // <-- Use this to select current element
		triggerHook: 1,
		duration:height,
	})
	.setTween(animationMask)
	.addTo(controller);
	
	if ($("body").hasClass("smooth-scroll")) {
		scrollbar.addListener(() => {
			animationMaskScene.refresh()
		});
	}
})





